import React from 'react';
import * as b from './bootstrap.module.css';
import Icon from "../components/icon.tsx";
import PropTypes from 'prop-types';
import Translate from './translate';

const OutProduct = ({ item, className, defaultDescription, defaultIcon, withIcon, withoutText}) => {
  const css = (className) ? className : [b.btn, 'btn-info', b.textWhite, "btn-normal", b.mt2].join(' ');

  const OnHandleClick = (e) => {
    e.preventDefault();

    const amount = document.querySelector('[data-wniosker-amount]')?.dataset?.wnioskerAmount;
    const period = document.querySelector('[data-wniosker-period]')?.dataset?.wnioskerPeriod;

    const link = amount && period 
      ? `https://out.akredo.pl/${item?.url}?amount=${amount}&period=${period}` 
      : `https://out.akredo.pl/${item?.url}`;

    window.open(link, '_blank');
  };

  if (item?.url?.endsWith('volkswagen')) {
    return null;
  }

  if(withoutText === true){
    return (
      <button className={[b.btn, "btn-light", b.ml2].join(" ")} onClick={OnHandleClick}>
        <Icon size={20} icon="Logout" className={[b.mr1, "icon-blog"].join(" ")}/>
      </button>
    )
  }

  return (
    <Translate tKey={defaultDescription}>
      {(value) => (
         <button className={css} onClick={OnHandleClick}>
          {withIcon && <Icon color="#fff" size={20} icon={defaultIcon} className={[b.mr1, "icon-width"].join(' ')} />}
          {value}
        </button>
      )}
    </Translate>
  );
}

OutProduct.defaultProps = {
  withoutText: false,
  isbutton: false,
  withIcon: true,
  defaultDescription: "Ask for credit",
  defaultIcon: 'Headphones-simple'
};

OutProduct.propTypes = {
  withoutText: PropTypes.bool,
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  defaultDescription: PropTypes.string,
  defaultIcon: PropTypes.string,
  withIcon: PropTypes.bool,
};

export default OutProduct;